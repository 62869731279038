/*************************
        Helper
*************************/

body {
  overflow-x: hidden;
}

a {
  transition: all 0.3s ease-in-out;
}

a:focus, button:focus {
  outline: none;
}

// img holder
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

// Background overlay
.bg-overlay-dark-10 {
  &:before {
    background: rgba($black, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-20 {
  &:before {
    background: rgba($black, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-30 {
  &:before {
    background: rgba($black, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-40 {
  &:before {
    background: rgba($black, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-50 {
  &:before {
    background: rgba($black, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-60 {
  &:before {
    background: rgba($black, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-70 {
  &:before {
    background: rgba($black, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-80 {
  &:before {
    background: rgba($black, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-dark-90 {
  &:before {
    background: rgba($black, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-10 {
  &:before {
    background: rgba($white, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-20 {
  &:before {
    background: rgba($white, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-30 {
  &:before {
    background: rgba($white, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-40 {
  &:before {
    background: rgba($white, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-50 {
  &:before {
    background: rgba($white, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-60 {
  &:before {
    background: rgba($white, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-70 {
  &:before {
    background: rgba($white, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-80 {
  &:before {
    background: rgba($white, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-90 {
  &:before {
    background: rgba($white, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-primary-20 {
  &:before {
    background: rgba($primary, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-primary-50 {
  &:before {
    background: rgba($primary, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-primary-70 {
  &:before {
    background: rgba($primary, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-primary-80 {
  &:before {
    background: rgba($primary, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-primary-90 {
  &:before {
    background: rgba($primary, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  position: relative;
  z-index: 1;
}

/*** background ***/
.bg-black {
  background: $black;
}

/*** color ***/
.text-black {
  color: $black;
}

/*** rotate ***/
.rotate-180 {
  transform: rotate(180deg);
}

.rotate-n90 {
  transform: rotate(-90deg);
}

/*** positions ***/
.position-all-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

/*** Z index ***/
.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

/*** video-overvie ***/
.video-overview {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  right: 0px;
  text-align: left;
  font-size: 18px;
  align-self: center;
  align-items: center;

  i {
    transform: rotate(-33deg);
    margin-top: -2px;
  }

}


.video-overview {
  .btn-link {
    background-size: 2px 0%;
    background-position: 0% 0%;
    font-size: 18px;

    &:hover {
      background-size: 2px 100%;
      background-position: 0% 0%;
    }

  }

}

/*** bottom-border ***/
.border-bottom-sm {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 0px;
    height: 2px;
    width: 30px;
    background: $primary;
    transform: translateX(-50%);
  }

}

/*** extra font-size ***/
.font-xl {
  font-size: 140px;
  line-height: 130px;
}

.font-xxl {
  font-size: 180px;
  line-height: 170px;
  font-weight: 900;
  margin: 0 -5px -25px -5px;
  background: none;
  padding: 0;
  color: $primary;
}

/*** blockquote ***/
.blockquote.quote {
  position: relative;
  color: $gray-900;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 0;

  &:before {
    content: "\f110";
    font-family: Flaticon;
    font-size: 75px;
    font-weight: bold;
    position: absolute;
    left: 0px;
    top: -50px;
    color: rgba($black, .10);
    font-weight: 300;
  }

}

/*** Back To Top ***/
.back-to-top {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 40px;
    width: 40px;
    height: 40px;
    margin: 0px;
    font-size: 16px;
    color: $white;
    background: $primary;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    &:hover {
      background: $gray-900;
    }
    &:focus {
      background: $gray-900;
    }
  }
}

/*** about-inner-link hover ***/
.about-inner-link {
  a.btn {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.5s ease;
  }

  &:hover {
    a.btn {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    img {
      transform: translateY(10px);
    }

  }

  img {
    transition: all 0.5s ease;
    transform: translateY(-25px);
  }

}

.card-img-top{
  height: 100%;
}

/*** margin bottom 30 equal spacing like 15+15 = 30px bootstrap grid ***/
.mb-30 {
  margin-bottom: 30px;
}

.slider-content h4 > a{
  color: $white;
}

/***comming soon css***/
.facebook-bg {
  background-color: #445c8e; }

.twitter-bg {
  background-color: #43afe9; }

.google-bg {
  background-color: #dc0510; }

.linkedin-bg {
  background-color: #13799f; }

.social-bg-hover {
  position: relative;
  color: $white;
}

.social-bg-hover span {
  position: relative;
  color: $white;
}

.social-bg-hover:before {
  content: "";
  color: $white;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.social-bg-hover:hover {
  color: $white;
}

.social-bg-hover:hover:before {
  background-color: rgba(0, 0, 0, 0.1);
}
/*** section ***/
section {
  padding: 80px 0 60px;
}

/*** half banner bg ***/
.half-banner-bg-left {

  &:before {
    position: absolute;
    content: "";
    background: $primary;
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
  }

  &:after {
    position: absolute;
    content: "";
    background: url(../images/bg-slider/pattern-bg.png);
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
    z-index: 99;
    opacity: 0.05;
  }

}

.half-banner-bg-right {

  &:before {
    position: absolute;
    content: "";
    background: $primary;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
  }

  &:after {
    position: absolute;
    content: "";
    background: url(../images/bg-slider/pattern-bg.png);
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    opacity: 0.05;
  }

}


@include media-breakpoint-up(sm) {
  .w-md-40 {
    width: 40%;
  }

}


@include media-breakpoint-down(lg) {
  section {
    padding: 60px 0 40px;
  }

  .font-xl {
    font-size: 110px;
    line-height: 120px;
  }

}

@include media-breakpoint-down(md) {
  section {
    padding: 50px 0 30px;
  }

  .mb-md-30 {
    margin-bottom: 30px;
  }


}

@include media-breakpoint-down(sm) {
  section {
    padding: 40px 0 20px;
  }

  .half-banner-bg-left:after{
    content: none;
  }
  .half-banner-bg-left:before{
    content: none;
  }

  .responsive-overlap-md{
    background-position: left center !important;
  }

}

@include media-breakpoint-down(xs) {
  .font-xl {
    font-size: 70px;
    line-height: 70px;
  }

  .position-relative-sm {
    position: relative !important;
  }

}
