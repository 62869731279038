/*********************
    Header
*********************/

header {
  position: relative;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}

// header info
.header-contact-info {
  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 0.9rem;
  }

}

// header button
.header-call-button {
  width: 200px;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    height: 1px;
    left: 30px;
    right: 30px;
    background: rgba($white, .2);
  }

}

// header button
.mainmenu-right {
  width: 200px;
  text-align: center;
}

// header-style-02
.header.header-style-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .navbar-top {
    background: $white;
  }

  .navbar {
    .nav-link {
      color: $white;
    }

  }

}

// header-style-03
.header.header-style-03 {
  .navbar-top {
    margin-bottom: 30px;
  }

  .navbar {
    position: relative;
    top: 30px;
    z-index: 9;

    .nav-link {
      color: $white;
    }

  }

}

// header-style-04
.header.header-style-04 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .navbar-top {
    background: transparent;

    .nav {
      .nav-link {
        color: $white;
        &:hover{
          color: $primary;
        }
      }

    }

  }

  .navbar {
    .nav-link {
      color: $black;
    }

  }

}

// header-style-05
.header.header-style-05 {
  .navbar {
    position: relative;
  }

  .navbar-top {
    .nav-link {
      color: $white;
    }

  }

}

.header-wrapper {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    right: -80%;
    background: $black;
    width: 100%;
    height: 100%;
  }

}

.header.header-style-05 {
  button.navbar-toggler {
    right: 0px;
  }

}

// search-box
.search-box {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -60px;
  z-index: 102;
  width: 100%;
  background-color: $white;
  box-shadow: 0 .5rem 1rem rgba($black, .15);
  padding: 1rem;
  border-radius: 0;
  transition: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateY(0);
}

.search-box.open {
  visibility: visible;
  opacity: 1;
  transform: translateY(10px);
}

// sticky header
.header.is-sticky {
  position: fixed;
  width: 100%;
  background: $white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  .header-contact-info {
    display: none !important;
  }

  .navbar {
    position: absolute;
    top: 6px;
    right: 15px;
    border-top: none !important;
  }

}

// header-style-02 sticky header
.header.header-style-02.is-sticky {
  .navbar-top {
    padding: 10px 0;
  }

  .navbar {
    top: -65px;

    li {
      a {
        color: $black;
      }

    }

  }

}

// header-style-03 sticky header
.header.header-style-03.is-sticky {
  .navbar-top {
    display: none;
  }

  .position-relative {
    padding: 10px 0;
  }

  .navbar {
    background: none !important;
    top: -5px;

    li {
      a {
        color: $black;
      }

    }

  }

}

// header-style-04 sticky header
.header.header-style-04.is-sticky {
  .navbar-top {
    display: none;
  }

  .navbar {
    position: relative;
    top: inherit;
    right: inherit;

    .navbar-brand {
      padding: 10px 20px !important;
    }

  }

}


// header-style-05 sticky header
.header.header-style-05.is-sticky {
  .navbar-top {
    display: none;
  }

  .navbar {
    position: relative;
    top: inherit;
    right: inherit;
  }

}

@include media-breakpoint-up(lg) {
  .header {
    .dropdown-menu {
      margin: 0px;
      font-size: 14px;
      border-radius: 0px;
      border: none;
      right: 0;
      transform: translate3d(0, 10px, 0);
      transition: all 0.3s ease-in-out;
      transition-property: opacity, visibility, transform;
      transform-origin: top center;
      box-shadow: 0 5px 9px rgba(55, 125, 255, 0.075), 0 5px 9px rgba(119, 131, 143, 0.075);
      visibility: hidden;
      opacity: 0;
      display: block !important;
      padding: 6px 20px;
    }

    .dropdown {
      &:hover {
        >.dropdown-menu {
          transform: translate3d(0, 0, 0);
          visibility: visible;
          opacity: 1;
        }

      }

    }

  }

}

@media (max-width:1366px) {
.header-wrapper:before{
  right: -90%;
  }
}

@include media-breakpoint-down(md) {
  .navbar-brand {
    position: relative;
    z-index: 99;
  }

  .header {
    .navbar {
      .dropdown-menu {
        .dropdown-item {
          padding: 10px 15px;
        }

      }

    }

  }


  .header.is-sticky .navbar {
    top: 0;
    right: 0;
  }

  // header-style-02
  .header.header-style-02 {
    position: relative;

    .navbar {
      position: absolute;
      right: 0px;
      top: -65px;

      .nav-link {
        color: $black;
      }

    }

  }

  .header.header-style-02.is-sticky {
    position: fixed;

    .navbar-top {
      padding: 0px 0;
    }

    .navbar {
      top: -65px;
    }

  }

  // header-style-03
  .header.header-style-03 {
    .navbar-top {
      margin-bottom: 0;
    }

    .navbar {
      position: absolute;
      top: -9px;
      background: transparent !important;

      .nav-link {
        color: $black;
      }

    }

  }

  .header.header-style-03 {
    .position-relative {
      padding: 10px 0;
    }

  }

  // header-style-03 Sticky
  .header.header-style-03.is-sticky {
    .position-relative {
      padding: 10px 0;
    }

  }

  // header-style-04
  .header.header-style-04 {
    position: relative;

    .navbar-top {
      background: $black;

      .nav {
        .nav-link {
          color: $white;
        }

      }

    }

  }

  .header.header-style-04 {
    button.navbar-toggler {
      top: 0px;
      position: relative;
    }

    .navbar {
      height: 60px;
    }

    .navbar-collapse {
      top: 60px;
    }

  }

  // header-style-04 sticky header
  .header.header-style-04.is-sticky {
    position: fixed;
    z-index: 99;

    .navbar-top {
      display: none;
    }

    .navbar {
      position: relative;
      top: inherit;
      right: inherit;
    }

    .container {
      padding-right: 0;
      padding-left: 0;
    }

  }


}

@include media-breakpoint-down(sm) {

  .header-wrapper:before {
    position: relative;
    content: none;
  }

}
