/*************************
        Testimonial
*************************/

// testimonial
.testimonial {
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0rem .6rem rgba($black, .15);
  }

}

// testimonial style 02
.testimonial-style-02 {
  .testimonial-avatar {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
  }

  .testimonial-name {
    flex: 0 0 200px;
  }

  &:hover {
    box-shadow: inherit;
  }

}

@include media-breakpoint-down(sm) {
  .testimonial-style-02 {
    .testimonial-avatar {
      margin: 0 auto;
    }

  }

}