/*********************
    Navbar
*********************/
.navbar-brand {
    img {
        height: 40px;
    }
}

.navbar-nav {

    .nav-link {
        margin-right: $navbar-nav-link-margin-x;
        margin-left: $navbar-nav-link-margin-x;
        position: relative;
        font-weight: 500;
        display: inline-block;
        padding: 15px 0;


        &:before {
            text-decoration: none;
            position: absolute;
            content: "";
            bottom: 15px;
            left: 0;
            right: 0;
            height: 2px;
            width: 0;
            background: $primary;
            transition: all 0.3s ease;
        }

        &:hover {
            &:before {
                width: 100% !important;
            }
        }
        &.active {
            &:before {
                width: 100% !important;
            }
        }

    }

}

.navbar-nav .nav-item:first-child .nav-link{
            margin-left: 0 !important;
        }

.navbar-nav .nav-item:last-child .nav-link{
    margin-right: 0 !important;
}


.navbar-light .navbar-nav .nav-link {
    color: $gray-700;
}

.navbar-dark .navbar-nav .nav-link{
    color: $white;
}

.navbar-dark .nav .nav-link {
    color: $white;
}


@include media-breakpoint-down(md) {
    .navbar {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        padding: 0.5rem 0rem;
        height: 65px;

        .nav-link {
            display: flex;

            i {
                margin-left: auto;
            }

        }

    }

    .navbar-nav .nav-item:first-child .nav-link {
        margin-left: $navbar-nav-link-margin-x !important;
    }

    .dropdown-toggle::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

    button.navbar-toggler {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .navbar-collapse {
        position: absolute;
        top: 65px;
        background: $white;
        z-index: 9;
        width: 100%;
        border-top: 1px solid $gray-200;
        padding: 10px;
        left: 0;
    }

    .navbar .dropdown-menu-lg {
        overflow-y: scroll;
        min-width: 100%;
        height: 300px;
    }

}
