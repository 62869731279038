/*************************
        Accordion
*************************/

// Accordion Style 2
.accordion {
	.card {
		border-bottom: 1px solid $gray-200 !important;

		.card-header {
			position: relative;
			cursor: pointer;
			text-align: left;

		  button{
		  	text-align: left;
			  position: relative;
			  padding-left: 20px;

			&:after {
				content: "+";
				position: absolute;
				left: 0;
				top: 0px;
				font-weight: 700;
				color: $primary;
				font-size: 16px;
			}

		}

			.btn {
				font-size: 18px;
				color: $black;
			}

		}

		.accordion-content {
			padding-bottom: 25px;
		}

	}

}

.accordion {
	.card-header {
		button[aria-expanded=false] {
			&:after {
				content: "+";
			}

		}

		button[aria-expanded=true] {
			&:after {
				content: "-";
			}

		}

	}

}
