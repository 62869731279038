/*********************
    Card
*********************/

.card{
	.card-body{
		label{
			margin-bottom: 0;
		}
		h5{
			margin-bottom: 6px;
		}
		a.btn{
			padding-top: 0;
		}
	}
}
