/*************************
        Counter
*************************/

// counter
.counter {
    .counter-icon{
        i{
            padding-right: 20px;
        }
    }

    label {
        color: $gray-600;
    }

    .timer {
        font-size: 2.5rem;
        font-weight: 500;
        color: $gray-900;
        line-height: 2.6rem;
    }

    .counter-extra {
        font-size: 2.5rem;
        line-height: 2.6rem;
        color: $gray-900;
    }

}

.counter-xl {
    .timer {
        font-size: 5rem;
        line-height: 5rem;
    }

}

.counter-xl {
    .counter-extra {
        font-size: 5rem;
        line-height: 5rem;
    }

}

.counter-lg {
    .timer {
        font-size: 3.25rem;
        line-height: 3.25rem;
    }

    .counter-extra {
        font-size: 52px;
        line-height: 52px;
    }

}

@include media-breakpoint-down(md) {
    .counter-xl {
        .timer {
            font-size: 60px;
            line-height: 60px;
        }

        .counter-extra {
            font-size: 60px;
            line-height: 60px;
        }

    }

    .counter-lg {
        .timer {
            font-size: 40px;
            line-height: 40px;
        }

        .counter-extra {
            font-size: 40px;
            line-height: 40px;
        }

    }

}