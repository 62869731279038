/*************************
        Error
*************************/

@include media-breakpoint-down(xs) {
	.notfound-404 {
		h1 {
			font-size: 100px;
			line-height: 100px;
		}

	}

}