/*************************
        Team
*************************/

.team {
  position: relative;
  margin-bottom: 20px;
}

.team {
  .team-content {
    position: absolute;
    bottom: 25px;
    margin: 0 20px;
    left: 0;
    right: 0;
  }

  .team-content-inner {
    background-color: $white;
    padding: 20px 20px;
    width: 100%;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    .team-social {
      margin-left: auto;

      .nav {
        .nav-link {
          padding-top: 0;
          padding-bottom: 0;
        }

      }

    }

    .team-contact {
      opacity: 0;
      position: absolute;
      bottom: 0px;
      left: 20px;
      transition: all 0.3s ease-in-out;
    }

  }

  &:hover {
    .team-content-inner {
      background-color: $primary;
      color: $white;
      padding: 20px 20px 90px;

      .team-info {
        .team-position {
          color: $white;
        }

      }

      .team-contact {
        opacity: 1;
        bottom: 20px;

        .nav {
          .nav-link {
            color: $white;

            &:hover {
              color: $black;
            }

          }

        }

      }

      .team-social {
        .nav {
          .nav-link {
            color: $white;

            &:hover {
              color: $black;
            }

          }

        }

      }

    }

    .team-info {
      .avatar-name {
        color: $white;
      }

    }

  }

}

.team-content-inner {
  .team-info {
    .avatar-name {
      margin-bottom: 0;
      font-weight: 600;
    }

    .team-position {
      color: $primary;
    }

  }

}

// team-02
.team-02 {
  display: flex;
  align-items: center;

  .team-avatar {
    flex: 0 50%;
  }

  .team-content {
    flex: 0 50%;
  }

  .team-content-inner {
    padding: 0px;

    .team-contact {
      margin-top: 20px;

      .nav {
        .nav-link {
          color: $gray-900;
          padding: 5px;

          &:hover {
            color: $primary;
          }

        }

      }

    }

    .team-desc {
      color: $gray-900;
    }

  }

}

.social a.text-muted:hover{
  color: $primary !important;
}

@include media-breakpoint-down(xs){
  .team .team-content {
    margin: 0 15px;
  }

}